<template>
  <div class="water-mark-box" :style="{ 'z-index': props.zIndex }"></div>
</template>

<script setup>
import watermark from '@/utils/watermark'
import { nextTick } from 'vue'
import { useStore } from 'vuex'
const props = defineProps({
  zIndex: {
    type: Number,
    default: 10
  },
  markOptions: {
    type: Object,
    default: () => {}
  }
})
const store = useStore()
const userInfo = store.getters.userInfo
// 渲染水印
const reloadWathermark = () => {
  // 删除之前的水印
  if (document.querySelector('.water-mark-box')) {
    document.querySelector('.water-mark-box').innerHTML = ''
  }
  // 重新渲染水印
  // let userInfo = this.$store.state.user.userInfo
  const { userName, employeeNo } = userInfo
  // let text = `${deptName}_${userName}_${mobileNo.slice(-4) || employeeNo}`
  const text = `${userName}_${employeeNo}`
  watermark({
    watermark_txt: text,
    className: `water-mark-box`,
    watermark_zindex: props.zIndex,
    ...props.markOptions
  })
  // watermark({watermark_txt: text, className:'water-mark-box',watermark_alpha: '0.3'})
}
nextTick(() => {
  // 初始化水印
  reloadWathermark()
  // 监听缩放重新渲染水印
  addEventListener('resize', reloadWathermark)
})
</script>

<style lang="scss" scoped>
.water-mark-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
</style>
